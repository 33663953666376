<template>
  <div  id="app">
    <nav class="navbar navbar-dark bg-dark justify-content-between">
      <a class="navbar-brand" href="#">Collaboration Tool</a>
      <form class="form-inline">
        <b-button :pressed="isViewDiscussions" variant="outline-light" sm class="my-sm-0" v-on:click="openViewDiscussions()">View Discussions</b-button>
      </form>
    </nav>
    <DiscussionList v-show="isViewDiscussions"/>
    <section v-show="!isViewDiscussions">
      <div class="row">
        <div class="col-9" id="mapContainer">
          <button type="button" id="addDiscussionButton" v-on:click="openAddNewDiscussion()" class="btn btn-dark">Add Discussion <i class="fa fa-comment-dots"></i></button>
          <Map/>
        </div>
        <template v-if="!isAddNewDiscussion">
          <div class="col-3 displayNone sideContainer border" id="flexContainer">
            <FlexContainer/>
          </div>
        </template>

          <template v-if="isAddNewDiscussion">
            <div class="col-3 border sideContainer">
              <NewDiscussion/>
            </div>
          </template>
          <template v-else>
            <div class="col-3 border sideContainer">
              <SideMenu/>
            </div>
          </template>
      </div>
    </section>


    <notifications group="errors" classes="vue-notification text-dark" :reverse="true"/>
    <notifications group="successNotifications" classes="vue-notification text-dark" :reverse="true"/>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";

import Map from './components/Map.vue'
import SideMenu from './components/SideMenu.vue'
import NewDiscussion from './components/NewDiscussion.vue'
import FlexContainer from './components/FlexContainer.vue'

import DiscussionList from './components/DiscussionList.vue'

import mapStateModule from "./state/modules/mapStateModule.js"

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    mapModule: mapStateModule,
  },
  strict: debug
})

export default {
  name: 'app',
  data () {
    return {
      isViewDiscussions: false
    }
  },
  components: {
    Map,
    SideMenu,
    FlexContainer,
    NewDiscussion,
    DiscussionList
  },
  store:store,
  computed: {
    isAddNewDiscussion: {
      get() {
        return this.$store.state.mapModule.isAddNewDiscussion
      }
    },
  },
  methods: {
    openAddNewDiscussion: function() {
      const current = this
      current.$store.commit("mapModule/setIfAddNewDiscussionClicked", !(current.$store.state.mapModule.isAddNewDiscussion))
      var view =  document.getElementById("map").__vue__.view
      view.graphics.removeAll()
      var flexContainer = document.getElementById("flexContainer")
      var map = document.getElementById("mapContainer")
      if (flexContainer) {
        flexContainer.classList.add("displayNone")
        map.classList.remove("col-6")
        map.classList.add("col-9")
      }
      if (current.isAddNewDiscussion) {
        map.classList.remove("col-6")
        map.classList.add("col-9")
      }
      else {
        map.classList.remove("col-6")
        map.classList.add("col-9")
      }
    },
    openViewDiscussions: function() {
      const current = this
      current.isViewDiscussions = !current.isViewDiscussions
    }
  }
}
</script>

<style>
.esri-icon-non-visible::before {
  content: "\e610" !important;
}
.esri-icon-visible::before {
  content: "\e611" !important;
}
.displayNone {
  display:none;
}
.sideContainer {
  /* border-style: ridge; */
  padding-left: 0!important;
  padding-right: 0!important;
}
div#mapContainer {
  /* padding-left: 0!important; */
  padding-right: 0!important;
}
/* html,body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
} */
#app {
  overflow: hidden;
  /* display: flex;
  flex-direction: column; */
  /* padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; */
}
</style>
