/* eslint-disable */
var baseApiUrl = ''
var appBaseUrl = ''
if (process.env.NODE_ENV === 'production') {
  baseApiUrl = 'https://collaboration-tool-demo-api.geodemosolutions.com/public/'
  appBaseUrl = 'https://collaboration-tool-demo.geodemosolutions.com/#/'
 }
else {
  baseApiUrl = 'http://localhost/collaboration-tool-demo-api/public/'
  appBaseUrl = 'http://localhost:7900/#/'
}

export {
  baseApiUrl,
  appBaseUrl
}
