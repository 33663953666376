<template>
  <div class=" tailor">
    <b-navbar class="navbar navbar-dark bg-orange-custom sticky-top">
      <a class="navbar-brand" href="#">Discussions <i class="fa fa-comments"></i></a>
      <button class="btn btn-outline-light btn-sm" v-on:click="refreshDiscussions()" right><i class="fa fa-sync-alt"></i></button>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-dropdown v-b-tooltip.hover title="User" variant="outline-light" :text="userFilter" size="sm" class="m-2" :disabled="!filteredFeatureList.length" right>
            <b-dropdown-item-button v-on:click="userFilterChange('User')">All</b-dropdown-item-button>
            <template v-if="currentUser">
              <b-dropdown-item-button v-on:click="userFilterChange(currentUser.fullName)">{{currentUser.fullName}}</b-dropdown-item-button>
            </template>
          </b-dropdown>
          <!-- <b-dropdown v-b-tooltip.hover title="Status" variant="outline-light" :text="statusFilter" size="sm" class="m-2" :disabled="!filteredFeatureList.length" right>
            <b-dropdown-item-button v-on:click="statusFilterChange('Status')">All</b-dropdown-item-button>
            <b-dropdown-item-button v-for="(item, index) in statusFilterSet" :key="index" v-on:click="statusFilterChange(item)">{{item}}</b-dropdown-item-button>
          </b-dropdown>
          <b-dropdown v-b-tooltip.hover title="Discipline" variant="outline-light" :text="disciplineFilter" size="sm" class="m-2" :disabled="!filteredFeatureList.length" right>
            <b-dropdown-item-button v-on:click="disciplineFilterChange('Discipline')">All</b-dropdown-item-button>
            <b-dropdown-item-button v-for="(item, index) in disciplineFilterSet" :key="index" v-on:click="disciplineFilterChange(item)">{{item}}</b-dropdown-item-button>
          </b-dropdown> -->

        </b-navbar-nav>
      </b-collapse>
      <button class="btn btn-outline-light btn-sm"  @click="showFilter = !showFilter"><i class="fa fa-filter"></i></button>


    </b-navbar>
    <div class="row">
      <div class="col list-group">
        <template v-if="showFilter">
          <li class="list-group-item">
            <Multiselect
              v-model="selectedFilter"
              :options="filterOptions"
              group-values="items"
              group-label="filterTitle"
              :group-select="true"
              label="name"
              track-by="name"
              placeholder="Filter"
              multiple
              >
            </Multiselect>
          </li>
        </template>
        <template v-if="filteredFeatureList.length">
          <a href="#" v-for="item in filteredFeatureList" :key="item.objectId" class="list-group-item list-group-item-action" v-on:click="selectDiscussionFromList(item.objectId)">
            <div class="row">
              <div class="col-1 ml-3">
                <i class="fa fa-user"></i>
              </div>
              <div class="col-10">
                <div class="d-flex w-100 justify-content-between">
                  <p style="margin-bottom: 0.25rem;"><span class="badge badge-secondary badge-pill badge-custom">{{item.objectId}}</span><small> {{item.createdBy + " - " + item.priority}}</small></p>
                  <small class="text-muted">{{getFormattedDate(item.date)}}</small>


                </div>
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-0 font-weight-normal">
                    <small>"{{item.message.length > 20 ? item.message.substring(0, 30) + '...' : item.message}}"</small>
                  </p>
                  <p style="margin-bottom: 0.25rem;">
                    <small class="text-muted">{{item.disciplineList.join(", ")}}</small>&nbsp;&nbsp;
                    <template v-if="item.approvalStatus == 'Approved'">
                      <span class="badge badge-success badge-pill">{{item.approvalStatus}}</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-danger badge-pill">{{item.approvalStatus}}</span>
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </a>
        </template>
        <template v-else>
          <li class="list-group-item">{{filteredFeatureList.length == 0 ? "No Data Found":loadingStatus}}</li>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import { loadModules } from 'esri-loader';
import {baseApiUrl} from "../../config.js"
import axios from "axios"
import Multiselect from "vue-multiselect"

export default {
  name: 'SideMenu',
  components: {
    Multiselect
  },
  data () {
    return {
      showFilter: false,
      view: null,
      discussionIds: [],
      discussionSceneLayer: null,
      highlight: null,
      highlightArray: [],
      disciplineFilterSet: ['Cost Estimation and Quantities','Environmental','Road Design','Civil','Steering Committee','Health and Safety','Utilities','Flooding','Structures','Geotechnical','Management','Independent Review','Traffic','Drainage','Pavements','GIS','Commercial','Drafting','Lighting'],
      disciplineFilter: "Discipline",
      statusFilterSet: ['Approved','Pending'],
      statusFilter: "Status",
      userFilterSet: [],
      userFilter: "User",
      loadingStatus: "Loading...",
      selectedFilter: [],
      filterOptions: []
    }
  },
  computed: {
    webscene: {
      get() {
        return this.$store.state.mapModule.mapWebScene
      }
    },
    currentDiscussionId: {
      get() {
        return this.$store.state.mapModule.currentDiscussionId
      }
    },
    discussionFeatureList: {
      get() {
        return this.$store.state.mapModule.initialDiscussions
      }
    },
    discussionLayerId: {
      get() {
        return this.$store.state.mapModule.staticDiscussionLayerId
      }
    },
    currentUser: {
      get() {
        if(this.$store.state.mapModule.user){
          return this.$store.state.mapModule.user
        }
        else {
          return false;
        }
      }
    },
    filteredFeatureList: {
      get() {
        var finalList = this.discussionFeatureList
        let statusSelectedItems = []
        this.selectedFilter.forEach(item => {
          if(item.category == "status"){
            statusSelectedItems.push(item.name)
          }
        })

        if(statusSelectedItems.length > 0){
          finalList = finalList.filter(obj=>statusSelectedItems.includes(obj.approvalStatus))
        }

        let prioritySelectedItems = []
        this.selectedFilter.forEach(item => {
          if(item.category == "priority"){
            prioritySelectedItems.push(item.name)
          }
        })

        if(prioritySelectedItems.length > 0){
          finalList = finalList.filter(obj=>prioritySelectedItems.includes(obj.priority))
        }

        let disciplineSelectedItems = []
        this.selectedFilter.forEach(item => {
          if(item.category == "discipline"){
            disciplineSelectedItems.push(item.name)
          }
        })

        if(disciplineSelectedItems.length > 0){
          let filterList = []
          let disciplineSelected = disciplineSelectedItems.join(", ")

          disciplineSelectedItems.forEach(item => {
            finalList.forEach((finalItem, i) => {
                if(finalItem.disciplineList.includes(item)){
                  let output = {
                    objectId: finalItem.objectId,
                    disciplineList: finalItem.disciplineList,
                    createdBy: finalItem.createdBy,
                    // userName: finalItem.userName,
                    userEmail: finalItem.userEmail,
                    addressedTo: finalItem.addressedTo,
                    workPackage: finalItem.workPackage,
                    message: finalItem.message,
                    approvalStatus: finalItem.approvalStatus,
                    priority: finalItem.priority,
                    date: finalItem.date,
                    requestCreated: finalItem.requestCreated,
                    fileName: finalItem.fileName,
                    fileId: finalItem.fileId
                  }
                  filterList.push(output)
                }
            });
          });
          finalList = filterList.filter((value, index)=> filterList.indexOf(value) === index)
        }






        // if (this.disciplineFilter == "Discipline") {
        //   finalList =  this.discussionFeatureList
        // }
        // else {
        //   finalList = this.discussionFeatureList.filter(obj=>obj.disciplineList.includes(this.disciplineFilter))
        // }
        // if (this.statusFilter != "Status") {
        //   finalList = finalList.filter(obj=>obj.approvalStatus == this.statusFilter)
        // }
        if (this.userFilter != "User") {
          finalList = finalList.filter(obj=>obj.createdBy == this.userFilter)
        }

        return finalList
      }
    },
    webSceneLoadedFlag: {
      get() {
        return this.$store.state.mapModule.mapWebSceneLoadFlag
      }
    }
  },
  watch: {
    webSceneLoadedFlag: function() {
      const current = this
      if (current.webSceneLoadedFlag) {
        current.loadDiscussionList_noArcGIS()
      }
    },
    discussionFeatureList: function() {
      const current = this
      const vue = document.getElementById("map").__vue__
      const view = vue.view
      if (current.discussionFeatureList && current.webSceneLoadedFlag) {
        var objectId = window.location.href.split("/")[window.location.href.split("/").length - 1]
        if (!isNaN(parseInt(objectId))) {
          view.whenLayerView(current.discussionSceneLayer).then(()=>{
            current.selectDiscussionFromList(parseInt(objectId))
          })
        }
      }
    }
  },
  methods: {
    loadDiscussionList_noArcGIS: function(currentDiscussionLayer) {
      const current = this
      const vue = document.getElementById("map").__vue__
      const sceneView = vue.view
      if(currentDiscussionLayer){
        current.discussionSceneLayer = currentDiscussionLayer
      }
      else{
        current.discussionSceneLayer = sceneView.map.findLayerById(current.discussionLayerId);
      }
      current.$store.commit("mapModule/resetInitialDiscussions")
      current.discussionSceneLayer.graphics.items.forEach(item => {
        var featureObject = {}
        featureObject["objectId"] = item.attributes.id
        featureObject["disciplineList"] = item.attributes.disciplineList
        featureObject["createdBy"] = item.attributes.discussionCreatedBy
        // featureObject["userName"] = item.attributes.userName
        featureObject["userEmail"] = item.attributes.userEmail
        featureObject["addressedTo"] = item.attributes.addressedTo
        featureObject["workPackage"] = item.attributes.workPackage
        featureObject["message"] = item.attributes.message
        featureObject["approvalStatus"] = item.attributes.approvalState
        featureObject["priority"] = item.attributes.priority
        featureObject["date"] = item.attributes.date
        featureObject["requestCreated"] = item.attributes.requestCreated
        featureObject["fileName"] = item.attributes.fileName
        featureObject["fileId"] = item.attributes.fileId
        current.discussionIds.push(item.attributes.id)
        current.$store.commit("mapModule/insertInitialDiscussions", featureObject)

      })
      current.loadingStatus = (current.discussionSceneLayer.graphics.items.length==0)?"No Data Found":""
      current.$store.dispatch("mapModule/fetchDiscussionComments", {objectIds: current.discussionIds})
    },
    selectDiscussionFromList:function(discussionId) {
      const current = this
      const vue = document.getElementById("map").__vue__
      const view = vue.view
      current.highlightArray.forEach(item=>{
        item.remove()
      })
      current.discussionSceneLayer = view.map.findLayerById(current.discussionLayerId);
      if (current.currentDiscussionId == discussionId) {
        current.$store.commit("mapModule/clearAttachments")
        current.$store.commit("mapModule/clearcCommentDiscipline")
        current.$store.commit("mapModule/clearCommentAdressedTo")
        current.$store.commit("mapModule/setCurrentDiscussionId", null)
        current.toggleFlexContainer()
      }
      else {
        current.closeFlexContainer()
        current.$store.commit("mapModule/clearAttachments")
        current.$store.commit("mapModule/clearcCommentDiscipline")
        current.$store.commit("mapModule/clearCommentAdressedTo")
        current.$store.dispatch("mapModule/setCurrentDiscussionDetails", discussionId)
        current.openFlexContainer()
        loadModules(["esri/tasks/support/Query"],{ css: true })
        .then(([Query]) => {
          view.whenLayerView(current.discussionSceneLayer).then(function(discussionLayerView){
            var selectedFeature = current.discussionSceneLayer.graphics.items.filter(obj=>{
              return obj.attributes.id === discussionId
            })[0]
            if (selectedFeature) {
              current.highlightArray[discussionId] = discussionLayerView.highlight(selectedFeature);
              var center = selectedFeature.geometry
              var tilt = view.camera.tilt
              view.goTo({
                speedFactor: 0.5,
                zoom: 21,
                tilt: tilt,
                center: center
              });
            }
            else {
              current.closeFlexContainer()
            }
          })
        })
      }
    },
    clearTextData:()=>{
      var comment = document.getElementById("newCommentTextArea")
      if (comment) {
        comment.innerHTML = ""
        comment.value = ""
      }
    },
    toggleFlexContainer: function() {
      this.clearTextData()
      var flexContainer = document.getElementById("flexContainer")
      var map = document.getElementById("mapContainer")
      map.classList.toggle("col-9")
      flexContainer.classList.toggle("displayNone")
      map.classList.toggle("col-6")
    },
    closeFlexContainer: function() {
      this.clearTextData()
      var flexContainer = document.getElementById("flexContainer")
      var map = document.getElementById("mapContainer")
      flexContainer.classList.add("displayNone")
      map.classList.remove("col-6")
      map.classList.add("col-9")
    },
    openFlexContainer: function() {
      var flexContainer = document.getElementById("flexContainer")
      var map = document.getElementById("mapContainer")
      map.classList.remove("col-9")
      map.classList.add("col-6")
      flexContainer.classList.remove("displayNone")
    },
    disciplineFilterChange: function(selection) {
      this.disciplineFilter = selection
      if (this.filteredFeatureList.length == 0) {
        this.disciplineFilterChange("Discipline")
        this.$notify({
          group: 'errors',
          title: "Empty List",
          type: 'error',
          text: "No items in the list for "+selection,
          width: 500,
          duration: 1500
        })
      }
    },
    statusFilterChange: function(selection) {
      this.statusFilter = selection
      if (this.filteredFeatureList.length == 0) {
        this.statusFilterChange("Status")
        this.$notify({
          group: 'errors',
          title: "Empty List",
          type: 'error',
          text: "No items in the list for "+selection,
          width: 500,
          duration: 1500
        })
      }
    },
    userFilterChange: function(selection) {
      this.userFilter = selection
      if (this.filteredFeatureList.length == 0) {
        this.userFilterChange("User")
        this.$notify({
          group: 'errors',
          title: "Empty List",
          type: 'error',
          text: "No items in the list for "+selection,
          width: 500,
          duration: 1500
        })
      }
    },
    getFormattedDate: function(dateItem) {
      let date = new Date(dateItem)
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      month = month.toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let am_pm = date.getHours() >= 12 ? "pm" : "am";
      hours = hours < 10 ? "0" + hours : hours;
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let time = hours + ":" + minutes + " " + am_pm;
      return year + '-' + month + '-' + day + " " + time;
    },
    refreshDiscussions: function() {
      const current = this
      const vue = document.getElementById("map").__vue__
      const sceneView = vue.view
      var discussionSceneLayer = sceneView.map.findLayerById(current.discussionLayerId)
      sceneView.map.remove(discussionSceneLayer);
      sceneView.map.allLayers.refresh()
      current.$store.dispatch("mapModule/fetchDiscussionLayer").then(result=>{
        current.discussionSceneLayer = result
        sceneView.map.add(current.discussionSceneLayer)
        current.loadDiscussionList_noArcGIS(current.discussionSceneLayer)
      })

    },
    getSideMenuFilter: function() {
      const current = this
      current.filterOptions = []
      let url = baseApiUrl + "fetchSideMenuFilter"
      let data = {}
      let config = {
        responseType: 'json'
      }
      axios.post(url, data, config)
      .then((result) => {
        current.filterOptions = result.data
      })
    },
  },
  mounted() {
    const current = this
    current.getSideMenuFilter()
  }
}
</script>

<style>
  .panel-result {
    cursor: pointer;
    margin: 2px 0;
  }

  .panel-result:hover,
  .panel-result:focus,
  .panel-result.selected {
    background-color: rgba(200, 200, 200, 0.6);
  }
  .tailor {
    height: 93vh;
    max-height: 93vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .badge-custom {
    padding-right: 0.45em !important;
    padding-left: 0.45em !important;
    padding: 0.45em 0.45em !important;
  }

  .bg-orange-custom {
    background-color: #E66200 !important;
  }

</style>
