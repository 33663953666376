<template>
  <div class="" >
    <div class="mainFlex" v-for="item in currentDiscussion" :key="item.objectId">
      <div class=" messageThread">
        <br>
        <br>
        <div class="list-group" >
          <div class="col-12">
            <div class="row">
              <div class="col-10">
                <h3 class="font-weight-bold">
                  {{item.disciplineList.join(" ,")}}: #{{item.objectId}}
                </h3>
              </div>
              <a href="#" class="col-1" v-on:click="closeFlexContainer" v-b-tooltip.hover title="Close">
                <i class="fas fa-2x fa-times-circle"></i>
              </a>
            </div>
            <div class="list-group-item list-group-item-action">
              <div class="row">
                <div class="col-1">
                  <i class="fa fa-user"></i>
                </div>
                <div class="col-10">
                  <div class="d-flex w-100 justify-content-between">
                    <small>{{item.createdBy}}</small>
                    <small class="text-muted">{{getFormattedDate(item.date)}}</small>
                  </div>
                  <div class="d-flex w-100 justify-content-between">
                    <p class="mb-0 font-weight-bold">
                      {{item.message}}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="item.fileId" class="row">
                <div class="col-1"></div>
                <div class="col-8">
                  <i>
                    <small>
                      {{(item.fileName.length>=attachmentNameLengthLimit?item.fileName.split(",")[0].substring(0,attachmentNameLengthLimit)+'...'+item.fileName.split(".")[item.fileName.split(".").length-1]:item.fileName)}}
                    </small>
                  </i>
                </div>
                <div class="col-2">
                  <button type="button" v-on:click="downloadFile(item.fileId, 'discussionFile')" class="btn btn-light btn-sm mb-xl-2"><i class="fas fa-download"></i></button>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
        <div class="list-group" v-for="item in currentComments" :key="item.objectId">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="list-group-item list-group-item-action">
                <div class="row">
                  <div class="col-1">
                    <i class="fa fa-user"></i>
                  </div>
                  <div class="col-11">
                    <div class="d-flex w-100 justify-content-between">
                      <small>{{item.author_name}}</small>
                      <small class="text-muted pl-1">{{item.dateCreated}}</small>
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                      <p class="mb-0 font-weight-normal">
                        <small>{{item.comment_details}}</small>
                      </p>
                    </div>
                    <div class="row" v-for="(attachment, attIndex) in item.commentAttachments" :key="attIndex">
                      <div class="col-8">
                        <small>{{(attachment.fileName.length>=attachmentNameLengthLimit?attachment.fileName.split(",")[0].substring(0,attachmentNameLengthLimit)+'...'+attachment.fileName.split(".")[attachment.fileName.split(".").length-1]:attachment.fileName)}}</small>
                      </div>
                      <div class="col-2">
                        <button type="button" v-on:click="downloadFile(attachment.fileId, 'commentFile')" class="btn btn-light btn-sm mb-xl-2"><i class="fas fa-download"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
      <div class="messageBox bg-transparent border-top">
        <div class="row container mt-xl-3 mt-lg-2" v-if="!isCurrentDiscussionApproved">
          <div class="col-xl-1 col-lg-12">
            <button type="button" v-on:click="toggleFileModal" class="btn btn-light btn-sm mb-xl-2">
              <i class="fas fa-paperclip"></i>
            </button>
            <button type="button" v-on:click="toggleAddNotifyUserModal(item.disciplineList, item.addressedTo.split(','))" class="btn btn-light btn-sm mb-xl-2">
              <i class="fas fa-user-plus"></i>
            </button>
          </div>
          <div class="col-xl-8 col-lg-12">
            <textarea class="form-control" rows="3" v-model="newComment" id="newCommentTextArea"></textarea>
          </div>
          <div class="col-xl-3 col-lg-12">
            <button type="button" :disabled="newComment==''" class="col-xl-12 col-lg-6 btn btn-outline-primary mb-xl-2" v-on:click="addComment">
              Submit
            </button>
            <template v-if="isSameUser||isAdminUser">
              <button type="button" :disabled="newComment==''" class="col-xl-12 col-lg-6 btn btn-outline-success align-bottom" v-on:click="approveComment">
                Approve
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="openAddNotifyUserModal" @close="closeAddNotifyUserModal">
      <template slot="header">
        <h5 class="modal-title">Add user to notify</h5>
      </template>
      <div class="container">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Discipline</label>
          <div class="col-sm-8">
            <Multiselect
              v-model="selectedDiscipline"
              :options="disciplineList"
              label="name"
              track-by="code"
              placeholder="Discipline"
              hide-selected
              multiple
              >
            </Multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Notify</label>
          <div class="col-sm-8">
            <Multiselect
              id="notifyUser"
              v-model="addressedTo"
              :options="userFilteredList"
              group-values="users"
              group-label="organization"
              label="userEmail"
              track-by="userEmail"
              placeholder="Email"
              hide-selected
              multiple
              :taggable="true"
              @tag="addAddressedToTag"
              >
            </Multiselect>
          </div>
        </div>
      </div>
      <template slot="footer">
        <b-button type="button" variant="secondary" @click="closeAddNotifyUserModal">Close</b-button>
      </template>
    </modal>
    <modal :show.sync="openFileModalFlag">
      <template slot="header">
        <h5 class="modal-title">Upload Files</h5>
      </template>
      <template v-if="displayFilesFlag">
        <div class="row col-12" v-for="(item,index) in commentAttachments" :key="index">
          <div class="col-1">
            <button type="button" v-on:click="removeFileFromList(index)" class="btn btn-light btn-sm mb-xl-2"><i class="fas fa-times"></i></button>
          </div>
          <div class="col-8" style="overflow:hidden">
            {{(item.name.length>=attachmentNameLengthLimit?item.name.split(",")[0].substring(0,attachmentNameLengthLimit)+'...'+item.name.split(".")[item.name.split(".").length-1]:item.name)}}
          </div>
          <br>
          <br>
        </div>
      </template>
      <div class="row col-12">
        <div class="col-12">
          <b-form-file
            id="commentAttachmentContainer"
            v-model="file"
            ref="fileToUpload"
            :state="null"
            placeholder="Choose a file..."
            @change="attachFile"
            :file-name-formatter="function(){return 'Choose a file...'}"
            multiple
          ></b-form-file>
        </div>
      </div>
      <template slot="footer">
        <b-button type="button" variant="secondary" @click="toggleFileModal">Close</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
/*eslint-disable*/
import Vue from 'vue';
import modal from "@/components/Modal";
import axios from "axios"
import {baseApiUrl, appBaseUrl} from "../../config.js";
import Multiselect from "vue-multiselect"
export default {
  name: 'FlexContainer',
  components: {
    modal,
    Multiselect
  },
  data() {
    return {
      newComment:"",
      discussionSceneLayer: null,
      openFileModalFlag: false,
      openAddNotifyUserModal: false,
      file: null,
      displayFilesFlag: false,
      attachmentNameLengthLimit: 25,
      selectedDiscipline: [],
      addressedTo: [],
      adminEmails: [
        "dion.bowen@arcadis.com",
        "steven.lewis@arcadis.com",
        "rittick.borah@arcadis.com",
        "emily.hurry@arcadis.com",
        "leonardo.quines@arcadis.com"
      ],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },
  computed: {
    webscene: {
      get() {
        return this.$store.state.mapModule.mapWebScene
      }
    },
    discussionLayerId: {
      get() {
        return this.$store.state.mapModule.staticDiscussionLayerId
      }
    },
    currentComments: {
      get() {
        return this.$store.state.mapModule.currentComments
      }
    },
    currentDiscussion: {
      get() {
        return this.$store.state.mapModule.currentDiscussion
      }
    },
    currentDiscussionId: {
      get() {
        return this.$store.state.mapModule.currentDiscussionId
      }
    },
    // currentUserName: {
    //   get() {
    //     if (this.$store.state.mapModule.currentDiscussion) {
    //       return this.$store.state.mapModule.currentDiscussion[0].userName.toLowerCase()
    //     }
    //     else {
    //       return null
    //     }
    //   }
    // },
    currentDiscussionUserEmail: {
      get() {
        if (this.$store.state.mapModule.currentDiscussion) {
          return this.$store.state.mapModule.currentDiscussion[0].userEmail.toLowerCase()
        }
        else {
          return null
        }
      }
    },
    isCurrentDiscussionApproved: {
      get() {
        if (this.$store.state.mapModule.currentDiscussion) {
          return this.$store.state.mapModule.currentDiscussion[0].approvalStatus=="Approved"?true:false
        }
        else {
          return null
        }
      }
    },
    currentDiscussionApprovalState: {
      get() {
        if (this.$store.state.mapModule.currentDiscussion) {
          return this.$store.state.mapModule.currentDiscussion[0].approvalStatus.toLowerCase()
        }
        else {
          return null
        }
      }
    },
    // userName: {
    //   get() {
    //     if (this.$store.state.mapModule.userName) {
    //       return this.$store.state.mapModule.userName.toLowerCase()
    //     }
    //     else {
    //       return null
    //     }
    //   }
    // },
    userEmail: {
      get() {
        if (this.$store.state.mapModule.userEmail) {
          return this.$store.state.mapModule.userEmail.toLowerCase()
        }
        else {
          return null
        }
      }
    },
    user: {
      get() {
        return this.$store.state.mapModule.user
      }
    },
    discipline: {
      get() {
        if (this.$store.state.mapModule.currentDiscussion) {
          return this.$store.state.mapModule.currentDiscussion[0].disciplineList
        }
        else {
          return null
        }
      }
    },
    commentAttachments: {
      get() {
        return this.$store.state.mapModule.commentAttachments
      }
    },
    isSameUser: {
      get() {
        if (this.userEmail == this.currentDiscussionUserEmail) {
          return true
        }
        else {
          return false
        }
      }
    },
    isAdminUser: {
      get() {
        if (this.adminEmails.includes(this.userEmail.toLowerCase())) {
          return true
        }
        else {
          return false
        }
      }
    },
    disciplineList: {
      get() {
        return this.$store.state.mapModule.disciplineList
      }
    },
    userList: {
      get() {
        return this.$store.state.mapModule.userList
      }
    },
    userFilteredList: {
      get() {
        // console.log(this.selectedDiscipline);
        var finalList = this.userList
        var newList = []
        let selectedDisciplineCodes = []
        this.selectedDiscipline.forEach(item => {
            selectedDisciplineCodes.push(item.code)
        })
        if (selectedDisciplineCodes.length != 0) {
          this.userList.forEach((userListItem) => {
            let filteredUser = {
                organization:userListItem.organization,
                users: []
            }
            userListItem.users.forEach((item) => {
              if(selectedDisciplineCodes.includes(item.disciplineCode)){
                let userItem = {
                  userEmail:item.userEmail,
                  disciplineCode:item.disciplineCode
                }
                filteredUser.users.push(userItem)
              }
            });
            if(!selectedDisciplineCodes.includes("gis") && userListItem.organization == "Arcadis"){
              let usertest1 = {
                userEmail: 'leonardo.quines@arcadis.com',
                disciplineCode:this.selectedDiscipline
              }
              let usertest2 = {
                userEmail: 'sheena.verdad@arcadis.com',
                disciplineCode:this.selectedDiscipline
              }
              filteredUser.users.push(usertest1)
              filteredUser.users.push(usertest2)
            }
            newList.push(filteredUser);
          });
          finalList = newList
        }
        return finalList
      }
    },
    currentDiscipline: {
      get() {
        return this.$store.state.mapModule.commentDiscipline
      }
    },
    currentAdressedTo: {
      get() {
        return this.$store.state.mapModule.commentAdressedTo
      }
    },
  },
  methods: {
    approveComment: function() {
      // call addComment
      const current = this
      current.saveChangeDiscussionDetails().then(data=>{
        current.saveComment(true).then(data=>{
          current.newComment = ""
          var payload = {
            objectId: current.currentDiscussionId,
            comment: data.result,
            decision: true,
            approvalStateCode: "approved"
          }
          current.$store.commit("mapModule/addNewComment", payload)
          current.$store.dispatch("mapModule/setCurrentDiscussionDetails", current.currentDiscussionId)
          var updatePayload = {
            objectId: current.currentDiscussionId,
            decision: true,
            approvalStateCode: "approved"
          }
          current.$store.commit("mapModule/finalizeDiscussion", updatePayload)
          current.refreshDiscussions()
          current.sendDiscussionCommentEmail(data.result, true)
          // change value of decision in item in discussionList from state
        })
      })

    },
    refreshDiscussions: function() {
      const current = this
      const vue = document.getElementById("map").__vue__
      const sceneView = vue.view
      var discussionSceneLayer = sceneView.map.findLayerById(current.discussionLayerId)
      sceneView.map.remove(discussionSceneLayer);
      sceneView.map.allLayers.refresh()
      current.$store.dispatch("mapModule/fetchDiscussionLayer").then(currentDiscussionLayer=>{
        sceneView.map.add(currentDiscussionLayer);
      })
    },
    addComment: function() {
      const current = this
      current.saveChangeDiscussionDetails().then(data=>{
        current.saveComment().then(data=>{
          current.$store.commit("mapModule/clearAttachments")
          current.$store.commit("mapModule/clearcCommentDiscipline")
          current.$store.commit("mapModule/clearCommentAdressedTo")
          current.newComment = ""
          var payload = {
            objectId: current.currentDiscussionId,
            comment: data.result,
            decision: current.isCurrentDiscussionApproved,
            approvalStateCode: current.currentDiscussionApprovalState
          }
          current.$store.commit("mapModule/addNewComment", payload)
          current.$store.dispatch("mapModule/setCurrentDiscussionDetails", current.currentDiscussionId)
          current.sendDiscussionCommentEmail(data.result, current.isCurrentDiscussionApproved)
        })
      })

    },
    saveChangeDiscussionDetails: function () {
      const current = this
      var disciplineCodes = []
      var disciplineLabels = []
      if(current.selectedDiscipline.length>0){
        current.selectedDiscipline.forEach(item => {
            disciplineCodes.push(item.code)
            disciplineLabels.push(item.name)
        })
      }
      var addressedToEmail = []
      current.addressedTo.forEach(item => {
          addressedToEmail.push(item.userEmail)
      })
      return new Promise(function(resolve, reject) {
        var data = {
          discussionId:    current.currentDiscussionId,
          authorName:   current.user.email,
          disciplineCodeList: disciplineCodes,
          addressedTo: addressedToEmail
        }
        var url = baseApiUrl + "changeDiscussionDetails"
        var config  = {
          responseType: 'json'
        }
        axios.post(url, data, config).then((result)=>{
          if(result.data.status == "success"){
            let resultItem = result.data.result
            var updatePayload = {
              objectId: current.currentDiscussionId,
              addressedTo: resultItem.addressed_to,
              disciplineList: disciplineLabels
            }
            // console.log(updatePayload);
            current.$store.commit("mapModule/finalizeDetailsDiscussion", updatePayload)
            resolve(result.data)
          }
          else if(result.data.status == "failed"){
            current.$notify({
              group: 'errors',
              title: "Failed to save discussion details",
              type: 'error',
              text: result.data.message,
              width: 500,
              duration: -1
            })
          }

        })
        .catch(error=>{
          reject(error)
        })
      });
    },
    saveComment: function(approveFlag) {
      const current = this
      var isApproved = approveFlag?approveFlag:current.isCurrentDiscussionApproved
      var approvalStateCode = approveFlag?'approved':current.currentDiscussionApprovalState
      var attachmentsSuccessfulCount = 0
      return new Promise(function(resolve, reject) {
        var data = {
          discussionId:    current.currentDiscussionId,
          discipline:   current.discipline,
          decision:     isApproved,
          authorName:   current.user.email,
          commentDetails: current.newComment,
          approvalStateCode: approvalStateCode
        }
        var url = baseApiUrl + "newComment"
        var config  = {
          responseType: 'json'
        }
        axios.post(url, data, config).then((result)=>{
          if(result.data.status == "success"){
            if (current.commentAttachments.length > 0) {
              var fileURL = baseApiUrl + "addCommentAttachment"
              current.commentAttachments.forEach(item=>{
                var fileData = new FormData();
                fileData.append("fileinput"       , item);
                fileData.append("commentId"       , result.data.result.id);
                fileData.append("filename"        , item.name);
                fileData.append("filetype"        , item.type);
                var fileConfig = {
                  headers: {
                    'content-type': 'application/octet-stream'
                  },
                  responseType: 'json'
                }
                axios.post(fileURL, fileData,   fileConfig).then(fileResult=>{
                  result.data.result.commentAttachments.push(fileResult.data)
                  attachmentsSuccessfulCount+=1
                  if (attachmentsSuccessfulCount==current.commentAttachments.length) {
                    resolve(result.data)
                  }
                })
                .catch(error=>{
                  // error
                })
              })
            }
            else {
              resolve(result.data)
            }
          }
          else if(result.data.status == "failed"){
            current.$notify({
              group: 'errors',
              title: "Failed to create discussion",
              type: 'error',
              text: result.data.message,
              width: 500,
              duration: -1
            })
          }

        })
        .catch(error=>{
          reject(error)
        })
      });
      // add discussion to database,
      // refresh discussion list from database dataset
    },
    closeFlexContainer: function() {
      const current = this
      var flexContainer = document.getElementById("flexContainer")
      var map = document.getElementById("mapContainer")
      flexContainer.classList.add("displayNone")
      map.classList.remove("col-6")
      map.classList.add("col-9")
      // data clean
      this.newComment = ""
      this.$store.commit("mapModule/clearAttachments")
      current.$store.commit("mapModule/clearcCommentDiscipline")
      current.$store.commit("mapModule/clearCommentAdressedTo")
    },
    sendDiscussionCommentEmail: function(data, decision) {
      const current = this
      var url = baseApiUrl + "sendDiscussionCommentEmail"
      var data    = {
        discussionid: current.currentDiscussionId,
        name: data.author_name,
        comment: data.comment_details,
        discipline: current.discipline,
        date: data.created_at,
        approvalstatus: decision,
        appBaseUrl: appBaseUrl
      }
      var config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        // success notify email
      }).catch((error)=> {
        // error
      })
    },
    getFormattedDate: function(dateItem) {
      let date = new Date(dateItem)
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      month = month.toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let am_pm = date.getHours() >= 12 ? "pm" : "am";
      hours = hours < 10 ? "0" + hours : hours;
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let time = hours + ":" + minutes + " " + am_pm;
      return year + '-' + month + '-' + day + " " + time;
    },
    toggleFileModal: function() {
      const current = this
      current.openFileModalFlag = !current.openFileModalFlag
    },
    closeAddNotifyUserModal: function(){
      const current = this
      current.openAddNotifyUserModal = false
      if(current.selectedDiscipline.length == 0){
        current.$notify({
          group: 'errors',
          title: "Discipline",
          type: 'error',
          text: "Discipline field was left blank",
          width: 500,
          duration: 2000
        })
        current.openAddNotifyUserModal = true
      }
      if(current.addressedTo.length == 0){
        current.$notify({
          group: 'errors',
          title: "Notify",
          type: 'error',
          text: "Notify field was left blank",
          width: 500,
          duration: 2000
        })
        current.openAddNotifyUserModal = true
      }
      current.$store.commit("mapModule/addCommentDiscipline", current.selectedDiscipline)
      current.$store.commit("mapModule/addCommentAdressedTo", current.addressedTo)

    },
    toggleAddNotifyUserModal: function(currentDisciplineList, currentNotifiedUser) {
      const current = this
      // console.log(currentNotifiedUser);
      current.openAddNotifyUserModal = !current.openAddNotifyUserModal
      if(current.openAddNotifyUserModal){
        if(current.currentDiscipline.length == 0 && current.currentAdressedTo.length == 0){
          current.selectedDiscipline = currentDisciplineList?current.$store.state.mapModule.disciplineList.filter(obj=>currentDisciplineList.includes(obj.name)):[]
          let currentNotified = []
          currentNotifiedUser.forEach(item => {
            let userItem = {
              userEmail:item
            }
            currentNotified.push(userItem)
          });
          current.addressedTo = currentNotified
        }
      }
    },
    attachFile: function(event) {
      const current = this
      current.displayFilesFlag = false
      var files = event.target.files
      files.forEach(item=>{
        current.$store.commit("mapModule/addAttachment", item)
      })
      current.displayFilesFlag = true
      current.file = null
    },
    removeFileFromList: function(index) {
      this.$store.commit("mapModule/removeAttachment", index)
    },
    downloadFile: function(fileId, type) {
      let formData = new FormData();
      let url = baseApiUrl + "downloadAttachment";
      let config = {
        headers: {
          'content-type': 'application/octet-stream'
        },
        responseType: 'blob'
      }
      formData.append("fileId", fileId);
      formData.append("type", type);
      axios.post(url, formData, config).then((result) => {
        var disposition = result.headers['content-disposition'];
        // fix this matches
        var matches = /=(.*)/.exec(disposition);
        var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
        // filename = filename.replace(/"*/g,"");
        var type = result.headers['content-type'];
        var link = document.createElement('a');

        document.body.appendChild(link)
        link.style = "display: none"
        var blob = new Blob(
          [result.data],
          {type: result.headers['content-type']}
        );
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    },
    addAddressedToTag: function (newTag) {
      const current = this
      const tag = {
        userEmail: newTag
      }
      if (current.reg.test(newTag)) {
        current.addressedTo.push(tag)
      }
      else {
        current.$notify({
          group: 'errors',
          title: "Error Adding Email",
          type: 'error',
          text: "Email entered is invalid.",
          width: 500,
          duration: 2000
        })
      }
    },
  },


}
</script>

<style lang="css" scoped>
  .mainFlex {
    height: 93vh;
    max-height: 93vh;
    display:block;
  }
  .messageThread {
    height: 85%!important;
    max-height: 85%!important;
    overflow: hidden;
    overflow-y: scroll;
  }
  .messageBox {
    height: 14.6%!important;
    /* border-top: ridge; */
    border-top-color: lightgray;
  }
  textarea {
    resize: none;
  }
  a {
    color: gray;
  }
  a:hover {
    color: gray;
  }
</style>
