<template>
  <div class="pr-5 pl-5">
    <table class="table">
      <thead>
        <tr>
          <th>Discussion No.</th>
          <th>Created By</th>
          <th>Notified</th>
          <th>Discipline</th>
          <th>Work Package</th>
          <th>Priority</th>
          <th>Status</th>
          <th>Attachments</th>
          <th>Actions</th>
        </tr>
        <tr>
          <td></td>
          <td>
            <input type="text" v-model="searchCreatedBy" placeholder="Search" class="form-control form-control-sm">
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" v-model="searchAddressedTo" placeholder="Search">
          </td>
          <td>
            <select class="form-control form-control-sm" v-model="selectedDiscipline" required>
              <option value="All" selected>All</option>
              <option v-for="(item, index) in disciplineFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </td>
          <td>
            <select class="form-control form-control-sm" v-model="selectedWorkPackage" required>
              <option value="All" selected>All</option>
              <option v-for="(item, index) in workPackageFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </td>
          <td>
            <select class="form-control form-control-sm" v-model="selectedPriority" required>
              <option value="All" selected>All</option>
              <option v-for="(item, index) in priorityFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </td>
          <td>
            <select class="form-control form-control-sm" v-model="selectedStatus" required>
              <option value="All" selected>All</option>
              <option v-for="(item, index) in statusFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <template v-if="filteredList.length">
          <tr v-for="item in discussionListFinal" :key="item.objectId">
            <td>{{item.objectId}}</td>
            <td>{{item.createdBy}}</td>
            <td>{{item.addressedTo}}</td>
            <td>{{item.disciplineList.join(", ")}}</td>
            <td>{{item.workPackage}}</td>
            <td>{{item.priority}}</td>
            <td>{{item.approvalStatus}}</td>
            <td>
              <template v-if="item.fileId" class="row">
                  <i>
                    <small>
                      {{(item.fileName.length>=attachmentNameLengthLimit?item.fileName.split(".")[0].substring(0,attachmentNameLengthLimit)+'...'+item.fileName.split(".")[item.fileName.split(".").length-1]:item.fileName)}}
                    </small>
                  </i>
                  <button type="button" v-on:click="downloadFile(item.fileId, 'discussionFile')" class="btn btn-light btn-sm mb-xl-2"><i class="fas fa-download"></i></button>
              </template>
            </td>
            <td>
              <a :href="appBaseUrl+item.objectId" v-on:click="goToObject(item.objectId)" target="_blank">
                <button class="btn btn-outline-secondary btn-sm" type="button" ><i class="fa fa-share"></i></button>
              </a>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <div class="">
              No data found.
            </div>
          </tr>
        </template>
      </tbody>
    </table>
    <paginate
      v-if="filteredList.length > 0"
      v-model="selectedPageNumber"
      :page-count="numberOfPages"
      :page-range="3"
      :margin-pages="2"
      :click-handler="selectNewPage"
      :prev-text="''"
      :next-text="''"
      :container-class="'pagination pagination-sm'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
    >
    </paginate>
    <button type="button" class="btn btn-secondary btn-circle btn-xl bottomright btn-orange" v-on:click="exportReport()" title="Export Report"><i class="fa fa-download"></i></button>
  </div>
</template>

<script>
/*eslint-disable*/
import { baseApiUrl, appBaseUrl } from "../../config.js"
import axios from "axios"
import Paginate from 'vuejs-paginate'

export default {
  name: 'DiscussionList',
  components: {
    Paginate
  },
  data() {
    return {
      disciplineFilterSet: ['Cost Estimation and Quantities','Environmental','Road Design','Civil','Steering Committee','Health and Safety','Utilities','Flooding','Structures','Geotechnical','Management','Independent Review','Traffic','Drainage','Pavements','GIS','Commercial','Drafting','Lighting'],
      statusFilterSet: ['Approved','Pending'],
      workPackageFilterSet: ['LH2RH','FB2L','RH2FB','CRR'],
      typeFilterSet: ['Constructability','Health & Safety','3D Model Discussion','Coordination','Design Discussion'],
      priorityFilterSet: ['Critical', 'Major', 'Minor'],
      selectedStatus: "All",
      selectedDiscipline: "All",
      selectedWorkPackage: "All",
      selectedType: "All",
      selectedPriority: "All",
      appBaseUrl: appBaseUrl,
      searchCreatedBy: "",
      searchAddressedTo: "",
      attachmentNameLengthLimit: 15,
      // numberOfPages: 0,
      perPageCount: 10,
      selectedPageNumber: 1
    }
  },
  computed: {
    discussionList: {
      get() {
        return this.$store.state.mapModule.initialDiscussions
      }
    },
    filteredList: {
      get() {
        var finalList = this.discussionList
        if (this.selectedDiscipline != "All") {
          finalList = this.discussionList.filter(obj=>obj.disciplineList.includes(this.selectedDiscipline))
        }
        if (this.selectedStatus != "All") {
          finalList = finalList.filter(obj=>obj.approvalStatus == this.selectedStatus)
        }
        if (this.selectedWorkPackage != "All") {
          finalList = finalList.filter(obj=>obj.workPackage == this.selectedWorkPackage)
        }
        if (this.selectedType != "All") {
          finalList = finalList.filter(obj=>obj.discussionType == this.selectedType)
        }
        if (this.selectedPriority != "All") {
          finalList = finalList.filter(obj=>obj.priority == this.selectedPriority)
        }
        if (this.searchCreatedBy != "") {
          finalList = finalList.filter(list => {
            return list.createdBy.toLowerCase().includes(this.searchCreatedBy.toLowerCase())
          })
        }
        if (this.searchAddressedTo != "") {
          finalList = finalList.filter(list => {
            return list.addressedTo.toLowerCase().includes(this.searchAddressedTo.toLowerCase())
          })
        }
        return finalList
      }
    },
    numberOfPages: {
      get() {
        return Math.ceil(this.filteredList.length / this.perPageCount)
      }
    },
    discussionListFinal: {
      get() {
        const current = this
        if(current.numberOfPages >= current.selectedPageNumber){
          return current.filteredList.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
        }
        else{
          current.selectedPageNumber = 1
          return current.filteredList.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
        }

      }
    }
  },
  methods: {
    goToObject: function(objectId) {
      sessionStorage.origURL = appBaseUrl+objectId
    },
    downloadFile: function(fileId, type) {
      let formData = new FormData();
      let url = baseApiUrl + "downloadAttachment";
      let config = {
        headers: {
          'content-type': 'application/octet-stream'
        },
        responseType: 'blob'
      }
      formData.append("fileId", fileId);
      formData.append("type", type);
      axios.post(url, formData, config).then((result) => {
        var disposition = result.headers['content-disposition'];
        // fix this matches
        var matches = /=(.*)/.exec(disposition);
        var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
        // filename = filename.replace(/"*/g,"");
        var type = result.headers['content-type'];
        var link = document.createElement('a');

        document.body.appendChild(link)
        link.style = "display: none"
        var blob = new Blob(
          [result.data],
          {type: result.headers['content-type']}
        );
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
        // link.remove();
      });
    },
    selectNewPage: function(pageNumber) {
      const current = this
      current.selectedPageNumber = pageNumber
    },
    exportReport: function() {
      let formData = new FormData();
      let url = baseApiUrl + "exportReport";
      let config = {
        headers: {
          'content-type': 'application/octet-stream'
        },
        responseType: 'blob'
      }
      axios.post(url, formData, config).then((result) => {
        var disposition = result.headers['content-disposition'];
        // fix this matches
        var matches = /=(.*)/.exec(disposition);
        var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
        // filename = filename.replace(/"*/g,"");
        var type = result.headers['content-type'];
        var link = document.createElement('a');

        document.body.appendChild(link)
        link.style = "display: none"
        var blob = new Blob(
          [result.data],
          {type: result.headers['content-type']}
        );
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

}
</script>

<style>
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.bottomright {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.btn-orange {
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.btn-orange:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.page-link {
  color: #E66200 !important;
}
.page-link:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
</style>
