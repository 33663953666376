/*eslint-disable*/
import axios from 'axios';
import {baseApiUrl} from "../../../config.js";
import { loadModules } from 'esri-loader';

const state = {
  mapWebScene: null,
  mapView: null,
  initialDiscussions: [],
  discussionComments: [],
  currentDiscussionId: null,
  currentDiscussion: null,
  currentComments: [],
  // userName: null,
  userEmail: null,
  user: null,
  commentAttachments: [],
  staticDiscussionLayerId: "discussion-graphics",
  staticDiscussionLayerTitle: "User Discussions",
  mapWebSceneLoadFlag: null,
  isAddNewDiscussion: false,
  userList: [],
  disciplineList: [],
  commentDiscipline: [],
  commentAdressedTo: []
}

function initialState() {
  return {
    mapWebScene: null,
    mapView: null,
    initialDiscussions: [],
    discussionComments: [],
    currentDiscussionId: null,
    currentDiscussion: null,
    currentComments: [],
    // userName: null,
    userEmail: null,
    user: null,
    commentAttachments: [],
    staticDiscussionLayerId: "discussion-graphics",
    staticDiscussionLayerTitle: "User Discussions",
    mapWebSceneLoadFlag: null,
    isAddNewDiscussion: false,
    userList: [],
    disciplineList: [],
    commentDiscipline: [],
    commentAdressedTo: []
  }
}
const mutations = {
  saveMapWebScene(state, data) {
    state.mapWebScene = data
  },
  saveMapView(state, data) {
    state.mapView = data
  },
  // saveUserName(state, data) {
  //   state.userName = data
  // },
  saveUserEmail(state, data) {
    state.userEmail = data
  },
  saveUser(state, data) {
    state.user = data
  },
  insertUserList(state, payload){
    // state.userList = data
    state.userList.push(payload)
  },
  insertInitialDiscussions(state, payload) {
    state.initialDiscussions.push(payload)
  },
  resetInitialDiscussions(state) {
    state.initialDiscussions = []
  },
  resetDiscussionComments(state) {
    state.discussionComments = []
  },
  insertDiscussionComments(state, payload) {
    state.discussionComments.push(payload)
  },
  setCurrentDiscussionId(state, payload) {
    state.currentDiscussionId = payload
  },
  setCurrentDiscussion(state, payload) {
    state.currentDiscussion = payload
  },
  setCurrentComments(state, payload) {
    payload.comments.forEach(item=>{
      state.currentComments.push(item)
    })
  },
  clearCurrentComments(state) {
    state.currentComments = []
  },
  insertDisciplines(state, payload) {
    state.disciplineList.push(payload)
  },
  addNewComment(state, payload) {
    var objectId = payload.objectId
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == objectId)
    if (currentComments.length) {
      currentComments[0].comments.push(payload.comment)
    }
    else {
      var discussionComment = {
        decision: payload.decision,
        approvalStateCode: payload.approvalStateCode,
        objectId: objectId,
        comments: [payload.comment]
      }
      state.discussionComments.push(discussionComment)
    }
  },
  finalizeDiscussion(state, payload) {
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload.objectId)
    currentDiscussion[0].approvalStatus = payload.decision?"Approved":currentDiscussion.approvalStatus
  },
  finalizeDetailsDiscussion(state, payload) {
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload.objectId)
    currentDiscussion[0].addressedTo = payload.addressedTo?payload.addressedTo:currentDiscussion[0].addressedTo
    currentDiscussion[0].disciplineList = payload.disciplineList.length>0?payload.disciplineList:currentDiscussion[0].disciplineList
  },
  clearAttachments(state) {
    state.commentAttachments = []
  },
  addAttachment(state, payload) {
    state.commentAttachments.push(payload)
  },
  removeAttachment(state, index) {
    state.commentAttachments.splice(index,1)
  },
  setMapWebSceneLoadFlag(state, payload) {
    state.mapWebSceneLoadFlag = payload
  },
  setIfAddNewDiscussionClicked(state, payload){
    state.isAddNewDiscussion = payload
  },
  clearcCommentDiscipline(state) {
    state.commentDiscipline = []
  },
  clearCommentAdressedTo(state) {
    state.commentAdressedTo = []
  },
  addCommentDiscipline(state, payload) {
    state.commentDiscipline = payload
  },
  addCommentAdressedTo(state, payload) {
    state.commentAdressedTo = payload
  },
}

const actions = {
  async fetchDiscussionComments ({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchComments"
      let data    = {
        discussionIds: payload.objectIds
      }
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        commit("resetDiscussionComments")
        result.data.forEach((item,index) => {
          commit('insertDiscussionComments', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  setCurrentDiscussionDetails({state, commit}, payload) {
    commit("setCurrentDiscussionId", payload)
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload)
    if (currentDiscussion.length) {
      commit("setCurrentDiscussion", currentDiscussion)
    }
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == payload)
    commit("clearCurrentComments")
    if (currentComments.length) {
      commit("setCurrentComments", currentComments[0])
    }
  },
  fetchDiscussionLayer({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      var url = baseApiUrl + "fetchDiscussions"
      var data = {}
      var config = {
        responseType: 'json'
      }
      axios.post(url, data, config).then(response=>{
        var discussionsList = response.data.features
        var discussionsCount = 0;
        loadModules([
          "esri/layers/GraphicsLayer",
          "esri/Graphic",
          "esri/PopupTemplate"
        ],{ css: true })
        .then(([
          GraphicsLayer,
          Graphic,
          PopupTemplate
        ]) => {
          const discussionGraphicsLayer = new GraphicsLayer({
            id: state.staticDiscussionLayerId,
            title: state.staticDiscussionLayerTitle
          });
          const popupTemplate = new PopupTemplate({
            title: "Discussion Details",
            content: [{
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "id",
                  label: "Object ID"
                },
                {
                  fieldName: "discussionCreatedBy",
                  label: "Created by"
                },
                {
                  fieldName: "message",
                  label: "Message"
                },
                {
                  fieldName: "disciplineList",
                  label: "Discipline"
                },
                {
                  fieldName: "workPackage",
                  label: "Work Package"
                },
                {
                  fieldName: "date",
                  label: "Date"
                },
                {
                  fieldName: "approvalState",
                  label: "Approval Status"
                },
              ]
            }]
          });
          discussionsList.forEach(discussion=>{
            var discussionEntry = new Graphic({
              geometry: {
                type: "point",
                latitude: discussion.geometry.latitude,
                longitude:discussion.geometry.longitude,
                z: discussion.geometry.height
              },
              attributes: discussion.attributes,
              symbol: {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                url: discussion.attributes.approvalState == "Approved"? "ApprovedMarker.png":"DefaultMarker.png",
                width: 20
              },
              popupTemplate: popupTemplate
            });

            discussionGraphicsLayer.graphics.push(discussionEntry)

          })
          resolve(discussionGraphicsLayer)
        })
      })
    });
  },
  fetchDisciplines({commit, dispatch}) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchDisciplines"
      let data    = {}
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        result.data.forEach((item,index) => {
          commit('insertDisciplines', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  fetchUsers({commit, dispatch}) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchUsers"
      let data    = {}
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        result.data.forEach((item,index) => {
          commit('insertUserList', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
}

const getters = {}



export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
