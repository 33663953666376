<template>
  <div class=" tailor">
    <nav class="navbar navbar-dark bg-orange-custom sticky-top" >
      <a class="navbar-brand" href="#">Add New Discussion <i class="fa fa-comment"></i></a>
      <a href="#" class="text-light" v-on:click="resetForm()" v-b-tooltip.hover title="Close">
        <i class="fas fa-2x fa-times-circle"></i>
      </a>
    </nav>
    <br>
    <div class="row container">
      <div class="col">
        Click the "Select Location" button and select a location in the 3D View
        <button v-on:click="addNewDiscussionLayer()" class="btn btn-primary" title="Click to start adding discussions">
        Select Location
        </button>
      </div>
    </div>
    <hr>
    <br>
    <template v-if="isLocationSelected">
      <div class="container">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Discipline</label>
          <div class="col-sm-8">
            <Multiselect
              v-model="selectedDiscipline"
              :options="disciplineOptions"
              label="name"
              track-by="code"
              placeholder="Discipline"
              hide-selected
              multiple
              >
            </Multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Work Package</label>
          <div class="col-sm-8">
            <select class="form-control" v-model="selectedWorkPackage" required>
              <option value="" disabled selected></option>
              <option v-for="workPackage in workPackageOptions"  :key="workPackage.id" :value="workPackage.code">{{workPackage.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Priority</label>
          <div class="col-sm-8">
            <select class="form-control" v-model="selectedPriority" required>
              <option value="" disabled selected></option>
              <option v-for="priority in priorityOptions"  :key="priority.id" :value="priority.code">{{priority.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Message</label>
          <div class="col-sm-8">
            <textarea style="resize:none;" rows="3" class="form-control" v-model="textRequest" required></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Notify</label>
          <div class="col-sm-8">
            <Multiselect
              v-model="addressedTo"
              :options="userFilteredList"
              group-values="users"
              group-label="organization"
              label="userEmail"
              track-by="userEmail"
              placeholder="Email"
              hide-selected
              multiple
              :taggable="true"
              @tag="addAddressedToTag"
              >
            </Multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">File Upload</label>
          <div class="col-sm-8">
            <b-form-file id="fileUpload"></b-form-file>
          </div>
        </div>
        <div class="text-right">
          <button v-on:click="submitNewDiscussionLayer()" class="btn btn-outline-primary mr-2" title="Click to submit discussion">
            Submit
          </button>
          <button v-on:click="resetForm()"
            class="btn btn-outline-danger"
             title="Click to cancel">
            Cancel
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable*/
import { loadModules } from 'esri-loader';
import {baseApiUrl, appBaseUrl} from "../../config.js"
import axios from "axios"
import Multiselect from "vue-multiselect"

export default {
  name: 'NewDiscussion',
  components: {
    Multiselect
  },
  data () {
    return {
      // userName: this.$store.state.mapModule.userName,
      userEmail: this.$store.state.mapModule.userEmail,
      DiscussionNo: "",
      textDiscussion: "",
      addressedTo: [],
      ccEmail: [],
      discussionCreatedBy: this.$store.state.mapModule.user.fullName,
      requestCreated: "",
      responseRequiredBy: "",
      updateBy: "",
      selectedType: "",
      textRequest: "",
      selectedDiscipline: [],
      selectedPriority: "",
      selectedOrganization: "",
      selectedWorkPackage: "",
      dueDate: "",
      approvalStatus: "Pending",
      isLocationSelected: false,
      selectedEmail: [],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      emails: [
        {
          value: "leonardo.quines@arcadis.com",
          label: "leonardo.quines@arcadis.com"
        },
        {
          value: "sheena.verdad@arcadis.com",
          label: "sheena.verdad@arcadis.com"
        },
        {
          value: "Rittick.Borah@arcadis.com",
          label: "Rittick.Borah@arcadis.com"
        },
        {
          value: "Nalin.Senevirathne@arcadis.com",
          label: "Nalin.Senevirathne@arcadis.com"
        }
      ],
      errorMsgDictionary: {
        noMessageFound: "Message field was left blank",
        noDisciplineFound: "Discipline field was left blank",
        noAddressedToFound: "Notify field was left blank",
        noWorkPackageFound: "Work Package field was left blank"
      },
      newDiscussionGeometry: {
        longitude: null,
        latitude: null,
        z: null
      },
      disciplineOptions: {},
      organizationOptions: {},
      workPackageOptions: {},
      priorityOptions: {},
    }
  },
  computed: {
    discussionLayerId: {
      get() {
        return this.$store.state.mapModule.staticDiscussionLayerId
      }
    },
    isAddNewDiscussion: {
      get() {
        return this.$store.state.mapModule.isAddNewDiscussion
      }
    },
    discussionFeatureList: {
      get() {
        return this.$store.state.mapModule.initialDiscussions
      }
    },
    userList: {
      get() {
        return this.$store.state.mapModule.userList
      }
    },
    userFilteredList: {
      get() {
        var finalList = this.userList
        var newList = []
        let selectedDisciplineCodes = []
        this.selectedDiscipline.forEach(item => {
            selectedDisciplineCodes.push(item.code)
        })
        if (selectedDisciplineCodes.length != 0) {
          this.userList.forEach((userListItem) => {
            let filteredUser = {
                organization:userListItem.organization,
                users: []
            }
            userListItem.users.forEach((item) => {
              if(selectedDisciplineCodes.includes(item.disciplineCode)){
                let userItem = {
                  userEmail:item.userEmail,
                  disciplineCode:item.disciplineCode
                }
                filteredUser.users.push(userItem)
              }
            });
            if(!selectedDisciplineCodes.includes("gis") && userListItem.organization == "Arcadis"){
              let usertest1 = {
                userEmail: 'leonardo.quines@arcadis.com',
                disciplineCode:this.selectedDiscipline
              }
              let usertest2 = {
                userEmail: 'sheena.verdad@arcadis.com',
                disciplineCode:this.selectedDiscipline
              }
              filteredUser.users.push(usertest1)
              filteredUser.users.push(usertest2)
            }
            newList.push(filteredUser);
          });
          finalList = newList
        }
        return finalList
      }
    },
    currentUser: {
      get() {
        return this.$store.state.mapModule.user
      }
    },
  },
  methods: {
    addAddressedToTag: function (newTag) {
      const current = this
      const tag = {
        userEmail: newTag
      }
      if (current.reg.test(newTag)) {
        current.addressedTo.push(tag)
      }
      else {
        current.$notify({
          group: 'errors',
          title: "Error Adding Email",
          type: 'error',
          text: "Email entered is invalid.",
          width: 500,
          duration: 2000
        })
      }
    },
    addCCEmailTag: function (newTag) {
      const current = this
      const tag = {
        userEmail: newTag
      }
      if (current.reg.test(newTag)) {
        current.ccEmail.push(tag)
      }
      else {
        current.$notify({
          group: 'errors',
          title: "Error Adding Email",
          type: 'error',
          text: "Email entered is invalid.",
          width: 500,
          duration: 2000
        })
      }
    },
    addNewDiscussionLayer: function() {
      const scene = this
      const vue = document.getElementById("map").__vue__
      const sceneView = vue.view
      const current = this
      sceneView.graphics.removeAll()
      loadModules([
        "dojo/on",
        "esri/Graphic"],{ css: true })
      .then(([
        on,
        Graphic]) => {
        var appMap = document.getElementById("map");
        appMap.style.cursor = "crosshair";
        var onceClick = on.once(sceneView, "click", (evt) => {
          if (appMap.style.cursor === "auto") {
            onceClick.remove();
            return;
          }
          sceneView.hitTest(evt).then((hitTestResult) => {
            if (hitTestResult.results.length) {
              var point = hitTestResult.results[0].mapPoint.clone()
            } else {
              var point = hitTestResult.ground.mapPoint.clone()
            }
            // point.z = 0
            var newDiscussionEntry = new Graphic({
              geometry: point,
              attributes: {},
              symbol: {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                url: "InitialMarker.png",
                width: 20
              }
            });
            sceneView.graphics.add(newDiscussionEntry);
            current.newDiscussionGeometry.longitude = point.longitude
            current.newDiscussionGeometry.latitude = point.latitude
            current.newDiscussionGeometry.z = point.z
            current.isLocationSelected = true
            appMap.style.cursor = "auto";
          })
        })
      })
    },
    saveValidation: function() {
      const current = this
      var errors = []
      if (current.selectedDiscipline.length == 0) {
        var errorMessage = {}
        errorMessage.errorTitle = "Discipline"
        errorMessage.errorBody = current.errorMsgDictionary.noDisciplineFound
        errors.push(errorMessage)
      }
      if (current.selectedWorkPackage == 0) {
        var errorMessage = {}
        errorMessage.errorTitle = "Work Package"
        errorMessage.errorBody = current.errorMsgDictionary.noWorkPackageFound
        errors.push(errorMessage)
      }
      if (current.textRequest == "") {
        var errorMessage = {}
        errorMessage.errorTitle = "Message"
        errorMessage.errorBody = current.errorMsgDictionary.noMessageFound
        errors.push(errorMessage)
      }
      if (current.addressedTo.length == 0) {
        var errorMessage = {}
        errorMessage.errorTitle = "Notify"
        errorMessage.errorBody = current.errorMsgDictionary.noAddressedToFound
        errors.push(errorMessage)
      }
      return errors
    },
    submitNewDiscussionLayer: function() {
      const current = this
      const vue = document.getElementById("map").__vue__
      const sceneView = vue.view
      var errors = current.saveValidation();
      if (errors.length > 0) {
        errors.forEach(item=>{
          current.$notify({
            group: 'errors',
            title: item.errorTitle,
            type: 'error',
            text: item.errorBody,
            width: 500,
            duration: -1
          })
        })
      }
      else {
        var dateToday = current.getDateToday()
        var fileUploadDom = document.getElementById("fileUpload");
        var discussionSceneLayer = sceneView.map.findLayerById(current.discussionLayerId)
        var recipients = []
        current.selectedEmail.forEach(item => {
          recipients.push(item.value)
        })
        var addressedToEmail = []
        current.addressedTo.forEach(item => {
            addressedToEmail.push(item.userEmail)
        })
        var ccEmailAddress = []
        current.ccEmail.forEach(item => {
          ccEmailAddress.push(item.userEmail)
        })
        ccEmailAddress.push(current.currentUser.email)

        var disciplineCodes = []
        current.selectedDiscipline.forEach(item => {
            disciplineCodes.push(item.code)
        })

        var data = {
          disciplineCodeList: disciplineCodes,
          priorityCode: current.selectedPriority,
          organizationCode: current.selectedOrganization,
          workPackageCode: current.selectedWorkPackage,
          approvalStateCode: current.approvalStatus.toLowerCase(),
          longitude: current.newDiscussionGeometry.longitude,
          latitude: current.newDiscussionGeometry.latitude,
          elevation: current.newDiscussionGeometry.z,
          dueDate: current.dueDate,
          discussionDetails: current.textDiscussion,
          addressedTo: addressedToEmail,
          ccEmail: ccEmailAddress,
          userEmail: current.$store.state.mapModule.user.email,
          requestCreated: current.requestCreated,
          updateBy: current.updateBy,
          message: current.textRequest,
          recipients: recipients
        }
        var newDiscussionURL = baseApiUrl + "saveInitialDiscussion"
        var config  = {
          responseType: 'json'
        }
        axios.post(newDiscussionURL, data, config).then(response=>{
          sceneView.map.remove(discussionSceneLayer);
          sceneView.map.allLayers.refresh()
          if(response.data.status == "success"){
            current.$store.dispatch("mapModule/fetchDiscussionLayer").then(currentDiscussionLayer=>{
              sceneView.map.add(currentDiscussionLayer);
              var latestDiscussion = currentDiscussionLayer.graphics.items[currentDiscussionLayer.graphics.items.length-1]
              var featureObject = {}
              featureObject["objectId"] = latestDiscussion.attributes.id
              featureObject["disciplineList"] = latestDiscussion.attributes.disciplineList
              featureObject["workPackage"] = latestDiscussion.attributes.workPackage
              featureObject["priority"] = latestDiscussion.attributes.priority
              featureObject["createdBy"] = latestDiscussion.attributes.discussionCreatedBy
              // featureObject["userName"] = latestDiscussion.attributes.userName
              featureObject["userEmail"] = latestDiscussion.attributes.userEmail
              featureObject["addressedTo"] = latestDiscussion.attributes.addressedTo
              featureObject["message"] = latestDiscussion.attributes.message
              featureObject["approvalStatus"] = latestDiscussion.attributes.approvalState
              featureObject["date"] = latestDiscussion.attributes.date
              featureObject["requestCreated"] = latestDiscussion.attributes.requestCreated
              featureObject["updateBy"] = latestDiscussion.attributes.updateBy
              current.uploadAttachment(latestDiscussion.attributes.id).then(fileDetail=>{
                if (fileDetail.hasFile) {
                  featureObject["fileName"] = fileDetail.fileName
                  featureObject["fileId"] = fileDetail.fileId
                }
                current.sendEmail(featureObject)
                current.$store.commit("mapModule/insertInitialDiscussions", featureObject)
                current.resetForm()
              })
            })
          }
          else if(response.data.status == "failed"){
            current.$notify({
              group: 'errors',
              title: "Failed to create discussion",
              type: 'error',
              text: response.data.message,
              width: 500,
              duration: -1
            })
          }
        })
      }
    },
    resetForm: function() {
      const current = this
      var view =  document.getElementById("map").__vue__.view
      current.textDiscussion = ""
      current.selectedDiscipline = []
      current.selectedPriority = ""
      current.dueDate = ""
      current.approvalStatus= "Pending"
      view.graphics.removeAll()
      document.getElementById("addDiscussionButton").click();
      current.newDiscussionGeometry = {
        longitude: null,
        latitude: null,
        z: null
      }
      current.$store.commit("mapModule/setIfAddNewDiscussionClicked", false)
      var map = document.getElementById("mapContainer")
      if (!current.isAddNewDiscussion) {
        map.classList.remove("col-6")
        map.classList.add("col-9")
      }
    },
    sendEmail: function(data) {
      const current = this
      var url = baseApiUrl + "sendEmail"

      var addressedToEmail = []
      current.addressedTo.forEach(item => {
          addressedToEmail.push(item.userEmail)
      })


      var ccEmailAddress = []
      current.ccEmail.forEach(item => {
        ccEmailAddress.push(item.userEmail)
      })
      ccEmailAddress.push(current.currentUser.email)

      var data = {
        addressedTo: addressedToEmail,
        ccEmail: ccEmailAddress,
        discussionid: data.objectId,
        name: data.createdBy,
        message: data.message,
        disciplineList: data.disciplineList,
        workPackage: data.workPackage,
        priority: data.priority,
        responseRequiredBy: data.responseRequiredBy,
        date: data.requestCreated,
        approvalstatus: data.approvalStatus,
        appBaseUrl: appBaseUrl
      }
      var config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        // success email notif
      }).catch((error)=> {
        // error
      })
    },
    getDateToday: function() {
      let date = new Date();
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      month = month.toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let am_pm = date.getHours() >= 12 ? "PM" : "AM";
      hours = hours < 10 ? "0" + hours : hours;
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
      return year + '-' + month + '-' + day + " " + time;
    },
    uploadAttachment: function(discussionId) {
      return new Promise(function(resolve, reject) {
        var attachmentsSuccessfulCount = 0
        var fileUploadDom = document.getElementById("fileUpload");
        if (fileUploadDom.files.length === 1) {
          var fileURL = baseApiUrl + "addDiscussionAttachment"
          fileUploadDom.files.forEach(item=>{

            var fileData = new FormData();
            fileData.append("fileinput"       , item);
            fileData.append("discussionId"       , discussionId);
            fileData.append("filename"        , item.name);
            fileData.append("filetype"        , item.type);
            var fileConfig = {
              headers: {
                'content-type': 'application/octet-stream'
              },
              responseType: 'json'
            }
            axios.post(fileURL, fileData, fileConfig).then(fileResult=>{
              fileResult.data.hasFile = true
              resolve(fileResult.data)
            })
            .catch(error=>{
              // error
            })
          })
        }
        else {
          resolve(
            {
              hasFile:false
            }
          )
        }

      });
    },
    getDisciplines: function() {
      const current = this
      current.disciplineOptions = {}
      let url = baseApiUrl + "fetchDisciplines"
      let data = {}
      let config = {
        responseType: 'json'
      }
      axios.post(url, data, config)
      .then((result) => {
        current.disciplineOptions = result.data
      })
    },
    getOrganizations: function() {
      const current = this
      current.organizationOptions = {}
      let url = baseApiUrl + "fetchOrganizations"
      let data = {}
      let config = {
        responseType: 'json'
      }
      axios.post(url, data, config)
      .then((result) => {
        current.organizationOptions = result.data
      })
    },
    getWorkPackages: function() {
      const current = this
      current.workPackageOptions = {}
      let url = baseApiUrl + "fetchWorkPackages"
      let data = {}
      let config = {
        responseType: 'json'
      }
      axios.post(url, data, config)
      .then((result) => {
        current.workPackageOptions = result.data
      })
    },
    getPriorities: function() {
      const current = this
      current.priorityOptions = {}
      let url = baseApiUrl + "fetchPriorities"
      let data = {}
      let config = {
        responseType: 'json'
      }
      axios.post(url, data, config)
      .then((result) => {
        current.priorityOptions = result.data
      })
    },
    // getUsers: function() {
    //   const current = this
    //   let url = baseApiUrl + "fetchUsers"
    //   let data = {}
    //   let config = {
    //     responseType: 'json'
    //   }
    //   axios.post(url, data, config)
    //   .then((result) => {
    //     current.$store.commit("mapModule/insertUserList", result.data)
    //   })
    // }
  },
  mounted() {
    const current = this
    current.requestCreated = new Date().toISOString().substring(0,10);
    current.discussionNo = current.discussionFeatureList.length == 0 ? "1":current.discussionFeatureList[current.discussionFeatureList.length - 1].objectId + 1
    current.getDisciplines()
    current.getOrganizations()
    current.getWorkPackages()
    current.getPriorities()
    // current.getUsers()
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .tailor {
    height: 912px;
    max-height: 99%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .multiselect__tag {
    background: gray !important;
    color: white !important;
  }
  .multiselect__tag-icon {
    background: gray !important;
    color: white !important;
  }
  .multiselect__tag-icon:after {
    color: white !important;
  }
  .multiselect__option--highlight {
    background: gray !important;
    outline: none;
    color: white;
  }
  .multiselect__option--highlight:after {
    background: gray !important;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 14px;
  }
</style>
